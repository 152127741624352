<template>
  <div class="purchase-detail-recode-list">
    <table style="width: 100%;" v-if="showRecordList && showRecordList.length > 0">
      <colgroup>
      <col width="200"/>
      <col width="220"/>
      <col width="80"/>
      <col width="120"/>
      <col width="300"/>
      </colgroup>
      <tr v-for="(item, index) in showRecordList" :key="index">
        <td>{{item.time}}</td>
        <td>{{statusMap[item.statusKey] ? statusMap[item.statusKey].replace('中', '') : ''}}</td>
        <td>{{item.name}}</td>
        <td>{{item.actionLabel}}</td>
        <td>{{item.remark ? item.remarkTitle + '：' : ''}}{{item.remark}}</td>
      </tr>
    </table>
    <div class="no-data" v-else>暂无处理记录</div>
  </div>
</template>

<script>
export default {
  props: {
    recordList: { type: Array, defualt: () => [] },
    statusMap: { type: Object, defualt: () => {} }
  },
  computed: {
    showRecordList () {
      let data = []
      let dataBase = JSON.parse(JSON.stringify(this.recordList))
      dataBase.forEach(v => {
        data.push({
          time: v.createTime,
          name: v.userName,
          actionLabel: v.statusActionLabel,
          statusKey: v.statusKey,
          remark: v.remark,
          remarkTitle: v.statusActionKey === 'draw_switch_wait_1' ? '提交说明' : '审批意见'
        })
        if (v.isBack) {
          data.push({
            time: v.backTime,
            statusKey: v.statusKey,
            name: v.backUserName,
            actionLabel: '撤销' + v.statusActionLabel,
            remark: v.backRemark,
            remarkTitle: '撤销说明'
          })
        }
      })
      data.sort((a, b) => {
        return new Date(a.time).getTime() - new Date(b.time).getTime()
      })
      return data
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-detail-recode-list {
  color: #515a6e;
  td {
    height: 30px;
  }
  .no-data {
    padding-bottom: 10px;
  }
}
</style>